import React, { useCallback, useEffect, useState } from 'react';
import { forceUpdate } from 'store/home/action';
import Dialog from '@mui/material/Dialog';
import DialogTitle from 'components/modal/DialogTitle';
import DialogContent from 'components/modal/DialogContent';
import PatientDetailsTitle from 'components/home/patientdetails/Title';
import PatientDetailsTabs from 'components/home/patientdetails/Tabs';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from 'components/modal/DialogActions';
import Snackbar from 'components/snackbar';

import { PatientDialogActions } from './dialogActions';
import { ErrorResponse } from 'graphql/GraphqlBaseQueryTypes';
import {
  setIsInEditMode,
  setRefreshPatientDetails,
  updateCareTeamMembers,
  updateDirtyTabs,
  updateClearPatientDetailsDialog,
  updateShowSuccessMessage,
  updateMedicationClaims,
  updateMedicationsData,
  updateMemberDetails,
  updatePatientProblems,
  updatePatientTags,
} from 'store/patientdetails/patientDetailsSlice';
import { usePatientSaveInfo } from 'components/home/patientdetails/patientdetails.hooks';
import { openQuickNote } from 'store/ui/modals/quickNote';
import {
  Patient,
  PatientCareTeamMember,
  PatientEditTagsTabViewModel,
  PatientMedicationClaim,
  PatientProblem,
  QuickNoteType,
} from 'graphql/graphqlTypes';
import { GetPatientByIdQuery } from 'graphql/hooks/getPatientById';
import { useGetPatientByIdQuery } from 'graphql/enhanced/getPatientById';
import { useCreateOrUpdatePatientMutation } from 'graphql/enhanced/createOrUpdatePatient';
import { useGetPatientTagsTabQuery } from 'graphql/enhanced/getPatientTagsTab';
import styled from 'styled-components';
import { toPatientForm } from './patientdetails.helpers';
import { COLORS } from 'consts/styles';
import { IMessageState } from 'features/letters';
import { IState } from 'store';
import { ICONS } from 'components/icon';
import { useIsMounted } from 'hooks';
import { ErrorBox } from './styles';
import Loader from 'components/loader';
import {
  clearMedications,
  clearPatientDetailsDialog,
} from 'store/patientdetails/patientDetails.helpers';

const StyledDialogContent = styled(DialogContent)`
  height: 75vh;
  background-color: ${COLORS.GREY4};
  padding: 24px;
`;

export interface PatientDetailsDialogStateProps {
  open: boolean;

  onClose(): void;

  onNew(patient: number, episode: number): void;
}

const PatientDetailsDialog = (props: PatientDetailsDialogStateProps) => {
  const { open, onClose, onNew } = props;
  const patientId = useSelector(
    (state: IState) => state.patientDetails.patientId
  );

  const episodeId =
    useSelector((state: IState) => state.patientDetails.episodeId) ?? null;

  const [patientTabValid, setPatientTabValid] = useState<boolean>(true);
  const [message, setMessage] = useState<IMessageState | null>();
  const [wasSaved, changeWasSaved] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const [updatePatient, { isLoading }] = useCreateOrUpdatePatientMutation();

  const {
    data: patientData,
    isFetching: patientDetailsLoading,
    isSuccess: patientDetailsIsSuccess,
  } = useGetPatientByIdQuery(
    { patientId: patientId },
    {
      skip: patientId === 0,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    data: patientTags,
    isFetching: patientTagsLoading,
    isSuccess: patientTagsIsSuccess,
    requestId: patientTagsRequestId,
  } = useGetPatientTagsTabQuery(
    { patientId },
    {
      skip: patientId === 0,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (
      patientTags?.getPatientTagsTab &&
      open &&
      !patientTagsLoading &&
      patientTagsIsSuccess
    ) {
      dispatch(
        updatePatientTags({
          tags:
            (patientTags.getPatientTagsTab.filter(
              (item) => item?.isActive
            ) as PatientEditTagsTabViewModel[]) ?? null,
          areUpdated: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientTags,
    open,
    patientTagsRequestId,
    patientTagsLoading,
    patientTagsIsSuccess,
  ]);

  useEffect(() => {
    const patient = patientData?.getPatientById;
    if (
      !open &&
      !patientDetailsIsSuccess &&
      patientDetailsLoading &&
      !patient
    ) {
      return;
    }

    const getEntityAttribute = (attribute: string) =>
      patient?.entityAttributes?.find((item) => item?.name === attribute)
        ?.value ?? '';
    dispatch(
      updateMemberDetails(toPatientForm(patientData as GetPatientByIdQuery))
    );
    dispatch(
      updateMedicationsData({
        allergiesCheck: getEntityAttribute('NoKnownDrugAllergies') === 'True',
        medicationsCheck:
          getEntityAttribute('DeniesActiveMedications') === 'True',
        allergyNotes: patient?.allergies ?? '',
        lastReviewed: {
          time: patient?.medicationLastReviewedOn ?? '',
          user: patient?.medicationLastReviewedBy?.fullName ?? '',
          isNew: false,
        },
      })
    );
    dispatch(
      updateMedicationClaims(
        patient?.medicationClaims as PatientMedicationClaim[]
      )
    );
    dispatch(
      updateCareTeamMembers({
        teamMembers:
          (patient?.careTeamMembers as Array<PatientCareTeamMember>) ?? null,
      })
    );
    dispatch(
      updatePatientProblems({
        patientProblems: (patient?.problems as Array<PatientProblem>) ?? null,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, patientData, patientDetailsIsSuccess, patientDetailsLoading]);

  const handleUpdatePatientTabValid = useCallback((valid: boolean) => {
    setPatientTabValid(valid);
  }, []);

  const handleCloseBase = (isRequired: boolean) => {
    if (wasSaved || isRequired) {
      dispatch(forceUpdate(true));
    }
    onClose();
  };

  const handleClose = () => {
    handleCloseBase(false);
  };

  const patientSaveInfo = usePatientSaveInfo();
  const dirtyTabs = useSelector(
    (state: IState) => state.patientDetails.dirtyTabs
  );
  const hasPendingItemsToSave = dirtyTabs.length > 0;
  const clearPatientDetails = useSelector(
    (state: IState) => state.patientDetails.clearPatientDetailsDialog
  );
  const isMounted = useIsMounted();

  const showSuccessMessage = useSelector(
    (state: IState) => state.patientDetails.showSuccessMessage
  );

  useEffect(() => {
    if (showSuccessMessage) {
      setMessage({
        active: true,
        type: 'success',
        title: 'Success',
        text: 'Member details saved successfully.',
      });
      dispatch(updateShowSuccessMessage(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSuccessMessage]);

  useEffect(() => {
    if (isMounted() && hasPendingItemsToSave) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasPendingItemsToSave]);

  const handleSave = async () => {
    if (patientSaveInfo) {
      setErrorMessage('');
      dispatch(setRefreshPatientDetails({ refreshPatientDetails: false }));
      const result = await updatePatient(patientSaveInfo);

      if ('error' in result) {
        setMessage({
          active: true,
          type: 'error',
          title: 'Error',
          text: 'Member details could not be saved.',
        });

        const errorResponse = result.error as ErrorResponse;
        setErrorMessage(errorResponse.errors.join(' - '));
        clearEditMode();
        dispatch(setRefreshPatientDetails({ refreshPatientDetails: true }));
        return;
      }

      changeWasSaved(true);
      clearEditMode();
      dispatch(updateShowSuccessMessage(true));
    }
  };

  const clearEditMode = () => {
    dispatch(updateDirtyTabs(''));
    dispatch(setIsInEditMode({ isInEditMode: false }));

    if (clearPatientDetails) {
      clearPatientDetailsDialog(dispatch);
    } else {
      clearMedications(dispatch);
      dispatch(updateClearPatientDetailsDialog(true));
    }
  };

  const handleOpenQuickNote = () => {
    dispatch(
      openQuickNote({
        patientId: patientId,
        episodeId: episodeId ?? 0,
        baseQuickNoteId: null,
        type: QuickNoteType.Episode,
      })
    );
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="xl"
        fullWidth={true}
        data-testid="patient-details-dialog"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          isCloseDisabled={hasPendingItemsToSave}
        >
          <PatientDetailsTitle
            patientInfo={
              patientData && (patientData?.getPatientById as Patient)
            }
          />
        </DialogTitle>
        {errorMessage && <ErrorBox>{errorMessage}</ErrorBox>}
        <StyledDialogContent dividers>
          {patientData ? (
            <PatientDetailsTabs
              handleUpdatePatientTabValid={handleUpdatePatientTabValid}
              patientDetails={toPatientForm(patientData)}
            />
          ) : null}
          <Loader
            active={isLoading || patientDetailsLoading || patientTagsLoading}
          />
        </StyledDialogContent>
        <DialogActions
          style={{ justifyContent: 'space-between', padding: ' 20px 24px' }}
        >
          <PatientDialogActions
            onClose={onClose}
            onNew={onNew}
            patientTabValid={patientTabValid}
            patientId={patientId}
            handleClickOpenQuickDialog={handleOpenQuickNote}
            handleClose={handleClose}
            isActionsDisabled={hasPendingItemsToSave}
          />
        </DialogActions>
      </Dialog>
      <Loader
        active={isLoading || patientDetailsLoading || patientTagsLoading}
      />
      {message && (
        <Snackbar
          icon={message.type === 'success' ? ICONS.Checkmark : ICONS.Warning}
          {...message}
          open={open && message?.active}
          onClose={() => setMessage({ ...message, active: false })}
          duration={message.type === 'success' ? 4000 : 'infinite'}
        />
      )}
    </>
  );
};

export default PatientDetailsDialog;
