import {
  ChecklistStorageType,
  IAllDocumentsState,
  IChecklistComponent,
  IChecklistState,
} from './types';
import { CalculateDateDue } from 'store/actions/Reminder.DueDate.helpers';
import {
  BaseComponent,
  ChecklistCategory,
  ChecklistItem,
  ChecklistItemHealthServices,
  ChecklistViewModel,
  Maybe,
  Scalars,
  DayType,
} from 'graphql/graphqlTypes';
import moment from 'moment';
import { getStoplights } from 'pages/workflow/helpers';
import { MOMENT_DATE_FORMAT, MOMENT_ISO_FORMAT } from 'components/constants';
import {
  DecisionAction,
  DeterminationDecision,
  HealthServiceConditionId,
} from 'components/actions/sections/SectionBody/Items/HealthService/types';

export const findCategoryItem = <T extends ChecklistItem>(
  checklist: ChecklistViewModel,
  predicate: (item: ChecklistItem) => boolean
) => {
  let item: T | undefined = undefined;
  for (const cat of checklist.categories) {
    item = cat.items.find(predicate) as T;
    if (item) {
      break;
    }
  }
  return item;
};

export const updateStoplightsVisibility = (
  state: IAllDocumentsState,
  storageType: ChecklistStorageType
) => {
  const visibleCategories = state.documentsState[
    storageType
  ].checklist?.categories.flatMap((i) =>
    i.items.map((x) => ({
      orderableId: x.orderableId,
      isVisible: x.isVisible,
    }))
  );

  state.documentsState[storageType].stoplightsRemaining.forEach((remaining) =>
    remaining.itemsWithStoplights.forEach((item) => {
      const update = visibleCategories?.find(
        (x) => x.orderableId === item.orderableId
      );
      if (!update) {
        return;
      }

      item.isVisible = update.isVisible;
    })
  );
};

export const getReminderDateDue = (
  value: Maybe<Scalars['String']> | string | undefined,
  daysForward: number,
  dayType: DayType
): Date | undefined => {
  let date = value
    ? moment(value, [
        MOMENT_DATE_FORMAT,
        MOMENT_ISO_FORMAT,
        moment.ISO_8601,
        moment.HTML5_FMT.DATE,
      ]).toDate()
    : undefined;

  if (date) {
    date = CalculateDateDue(date, dayType, daysForward);
  }

  return date;
};

export const getFlattenedComponents = (checklist: ChecklistViewModel) => {
  const components: { [key: string]: IChecklistComponent } = {};
  const linkedComponents: { [mirrorGroup: string]: string[] } = {};
  const categories =
    checklist.categories.concat(
      checklist.categories.flatMap((x) => x.subCategories)
    ) || [];

  categories.forEach((category, index) => {
    category.items.forEach((item, itemIndex) =>
      mapItem(item, index, itemIndex, components, linkedComponents)
    );
  });

  return { components, linkedComponents };
};

export const mapItem = (
  item: ChecklistItem,
  categoryIndex: number,
  itemIndex: number,
  components: { [key: string]: IChecklistComponent },
  linkedComponents: { [mirrorGroup: string]: string[] }
) => {
  if (!('lines' in item)) {
    return;
  }

  // hasRepeater === false means that it's a template component for repeating items (not a real checklist component)
  if (!item.hasRepeater && item.repeaterOptions?.canRepeat) {
    return;
  }

  item.lines.forEach((line, lineIndex) => {
    line.components.forEach((component, componentIndex) => {
      components[component.uniqueID] = mapComponent(
        component,
        item,
        categoryIndex,
        itemIndex,
        lineIndex,
        componentIndex
      );
      mapLinks(component, linkedComponents);
    });
  });
};

export const mapLinks = (
  component: BaseComponent,
  linkedComponents: { [mirrorGroup: string]: string[] }
) => {
  if ('mirrorGroup' in component && component.mirrorGroup) {
    linkedComponents[`mirror_${component.mirrorGroup}`] = [
      ...(linkedComponents[`mirror_${component.mirrorGroup}`] || []),
      component.uniqueID,
    ];
  }
  if ('oneWaySrc' in component && component.oneWaySrc) {
    linkedComponents[`oneway_${component.oneWaySrc}`] = [
      ...(linkedComponents[`oneway_${component.oneWaySrc}`] || []),
      component.uniqueID,
    ];
  }
  if ('daysForwardFrom' in component && component.daysForwardFrom) {
    linkedComponents[`reminder_source_${component.daysForwardFrom}`] = [
      ...(linkedComponents[`reminder_source_${component.daysForwardFrom}`] ||
        []),
      component.uniqueID,
    ];
  }
  if (
    'parentLookupComponentId' in component &&
    component.parentLookupComponentId
  ) {
    linkedComponents[`cascade_source_${component.parentLookupComponentId}`] = [
      ...(linkedComponents[
        `cascade_source_${component.parentLookupComponentId}`
      ] || []),
      component.uniqueID,
    ];
  }
};

export const initChecklistComponents = (
  state: IChecklistState,
  checklist: ChecklistViewModel
) => {
  const { components, linkedComponents } = getFlattenedComponents(checklist);
  state.checklist = checklist;
  state.checklistComponents = components;
  state.linkedComponents = linkedComponents;
  checklist.categories.forEach((category) =>
    category.items.forEach((item) => {
      state.selectedActions[item.uid] = item.isSelected;
    })
  );

  const uniqueStoplights = getStoplights(checklist);
  state.stoplightsRemaining = uniqueStoplights;
};

export const mapComponent = (
  component: BaseComponent,
  item: ChecklistItem,
  sectionIndex: number,
  itemIndex: number,
  lineIndex: number,
  componentIndex: number
): IChecklistComponent => ({
  uuid: item.uid,
  sectionId: sectionIndex + 1,
  itemIndex,
  lineIndex,
  componentIndex,
  component,
  isVisible: item.isVisible,
  orderableId: item.orderableId,
});

export const findHsConditionItem = (
  orderableId?: Maybe<string>,
  checklist?: ChecklistViewModel
) => {
  return checklist?.categories.map((category) =>
    category?.items?.find((item) => item.orderableId === orderableId)
  )[0] as ChecklistItemHealthServices;
};

export const findHsConditionItemCategory = (
  uId?: Maybe<string>,
  checklist?: ChecklistViewModel
) => {
  return checklist?.categories.find((categorie) =>
    categorie.items.some((item) => item.uid === uId)
  );
};

export const getHsConditionItemDecision = (
  decision: DeterminationDecision,
  conditionValue: string
): boolean => {
  switch (decision) {
    case DecisionAction.approved:
      return conditionValue === HealthServiceConditionId.approved
        ? true
        : false;
    case DecisionAction.partial:
      return conditionValue === HealthServiceConditionId.partial ? true : false;
    case DecisionAction.pending:
      return conditionValue === HealthServiceConditionId.pending ? true : false;
    case DecisionAction.denied:
      return conditionValue === HealthServiceConditionId.denied ? true : false;
    default:
      return false;
  }
};

export const findHsConditionItemIndex = (
  uId: Maybe<string>,
  category: ChecklistCategory
): number => {
  return category.items.findIndex((item) => item.uid == uId);
};
