import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { IState } from 'store';
import { useSetCurrentUserPreferenceMutation } from 'graphql/hooks/setCurrentUserPreference';
import { Select } from 'components/select';
import RefreshButton from 'components/refreshbutton';
import {
  BaseDashboardProps,
  getDashboardsByRole,
  userPreference,
} from './DashBoardGridHelper';

const StyledDivider = styled(Divider)`
  height: 24px;
  margin: 0;
`;

const StyledRefreshWrapper = styled.div`
  margin-left: -10px;
`;

export const homeModelSelector = createSelector(
  (state: IState) => state.home.homeModel,
  (model) => model
);

export const homeGridData = createSelector(
  (state: IState) => state.home.data,
  (model) => model
);

const DashboardGridHeaderPanel = ({
  userPreferences,
  setUserPreferences,
}: BaseDashboardProps) => {
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const roleType = useSelector((state: IState) => state.user.currentUser.type);
  const dashboards = getDashboardsByRole(currentRoleId, roleType);
  const [updateCurrentUserPreference] = useSetCurrentUserPreferenceMutation();
  const defaultDashboard = userPreferences.defaultDashboard ?? '';

  const handleSelectChange = (
    event: React.ChangeEvent<{ name: string | undefined; value: string }>
  ) => {
    const dashboard = event.target.value;
    updateCurrentUserPreference({
      name: userPreference.defaultDashboard,
      value: dashboard,
    });
    setUserPreferences({
      ...userPreferences,
      defaultDashboard: dashboard,
    });
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="16px"
      mb="16px"
      data-testid="dashboard-bar"
      data-cy="dashboard-bar"
    >
      <StyledRefreshWrapper>Dashboard: </StyledRefreshWrapper>
      <Box display="flex">
        <Select
          data-testid="dashboard-filter-select"
          items={dashboards}
          value={defaultDashboard}
          onChange={handleSelectChange}
          size="small"
          fullWidth
          required
          id="dashboard-filter-select"
        />
      </Box>

      <StyledDivider orientation="vertical" />
      <StyledRefreshWrapper>
        <RefreshButton
          onClick={() => {
            location.reload();
          }}
        />
      </StyledRefreshWrapper>
    </Box>
  );
};

export default DashboardGridHeaderPanel;
