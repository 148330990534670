import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { SummaryState, IntegratedSummary } from '@devexpress/dx-react-grid';
import { Grid, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { UserCaseLoadAndReminderCount } from 'graphql/graphqlTypes';
import { useGetCmUserCaseLoadAndReminderCountQuery } from 'graphql/hooks/getCMUserCaseLoadAndReminderCount';
import { DashboardCareSiteRole } from 'consts/roles';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import TooltipProvider from 'components/home/grid/providers/TooltipProvider';
import Loader from 'components/loader';
import DashValueProvider from './DashValueProvider';
import {
  columnsExtension,
  gridColumns,
  totalSummaryItems,
} from './CmDashBoardGrid.helper';
import CmDashboardGridHeader from './CmDashboardGridHeader';
import { BaseDashboardProps } from './DashBoardGridHelper';
import { summaryItem } from './DashBoardGrid';

const CmDashboardGrid = ({
  userPreferences,
  setUserPreferences,
}: BaseDashboardProps) => {
  const [careSiteId, setCareSiteId] = useState<string>(
    userPreferences.cmRnCaseLoadDashboardFilter ?? '0'
  );
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  ) as number;
  const isDashboardCareSiteRole = currentRoleId == DashboardCareSiteRole.roleId;
  const { data, isFetching } = useGetCmUserCaseLoadAndReminderCountQuery({
    careSiteId: isDashboardCareSiteRole
      ? Number(careSiteId)
      : currentCareSiteId,
  });
  const dashboardData = (data?.getCmUserCaseLoadAndReminderCount ??
    []) as UserCaseLoadAndReminderCount[];
  const showTotalSummary = dashboardData.length > 1;

  const handleChange = (careSiteId: string) => {
    setCareSiteId(careSiteId);
    setUserPreferences({
      ...userPreferences,
      cmRnCaseLoadDashboardFilter: careSiteId,
    });
  };

  if (isFetching) {
    return <Loader active={true} />;
  }
  return (
    <>
      <CmDashboardGridHeader
        isDashboardCareSiteRole={isDashboardCareSiteRole}
        careSiteId={careSiteId}
        onChange={handleChange}
      />
      <Grid
        rows={dashboardData}
        columns={gridColumns}
        getRowId={(row) => row.userId}
      >
        {showTotalSummary && <SummaryState totalItems={totalSummaryItems} />}
        <TooltipProvider for={['userName']} />
        <DashValueProvider
          for={[
            'totalCaseLoad',
            'caseManagementCaseLoad',
            'careTransitionCaseLoad',
            'eRFollowUpCaseLoad',
            'reminderDueThisWeek',
            'reminderDueToday',
            'reminderOverDue',
          ]}
        />
        <SymphonyTable columnExtensions={columnsExtension} />
        <SymphonyTableHeaderRow />
        {showTotalSummary && <IntegratedSummary />}
        {showTotalSummary && <TableSummaryRow itemComponent={summaryItem} />}
      </Grid>
    </>
  );
};

export default CmDashboardGrid;
