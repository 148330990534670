import React, { useEffect, useState } from 'react';
import {
  DATETIME_FORMAT,
  DATETIME_SECONDS_FORMAT,
  MOMENT_DATETIME_ISO_FORMAT,
  MOMENT_ISO_FORMAT,
  MOMENT_TIME_FORMAT,
  MOMENT_TIME_SECONDS_FORMAT,
} from 'components/constants';
import { IComponentProps } from '../types';
import { DateTimeComponent, Maybe } from 'graphql/graphqlTypes';
import { addDays, isEqual, subDays } from 'date-fns';
import {
  hasValue,
  IValidationResult,
  validateAttributeComparisonDates,
  validateDateField,
} from 'util/validationUtils';
import { useSelector, useDispatch } from 'react-redux';
import { useChecklistValidation } from '../../Items/Orderable';
import LinkFieldsIcon from 'components/linkFieldsIcon/LinkFieldsIcon';
import { IState } from 'store';
import moment from 'moment';
import { formatDateTime, getCareSiteNow } from 'util/helpers/dateTimeHelpers';
import styled from 'styled-components';
import DateComponent from 'components/DateComponent';
import TimeComponent from 'components/TimeComponent';
import { usePrevious } from 'hooks';
import dayjs from 'dayjs';
import { setEntityAttributesBeingSetValue } from 'store/actions/checklistSlice';
import { useAttributeComparison } from 'hooks/useAttributeValidation';
import { getDateTimeErrorMessage } from './dateTime.helper';

const StyledWrapper = styled.div`
  margin-right: 8px;
`;

export interface IDateTimeProps extends IComponentProps<DateTimeComponent> {
  open?: boolean;
  autoSave: boolean;
  isReadOnly: boolean;
  isUpdateActionValueRequestFailed: boolean;
  fieldName: Maybe<string> | undefined;
}

const getTimePlaceholder = (useSeconds: boolean) =>
  useSeconds ? 'hh:mm:ss' : 'hh:mm';

const isError = (
  fieldError: boolean,
  fullValueError: boolean,
  attributeValidationError: boolean,
  isReadOnly: boolean,
  isUpdateActionValueRequestFailed: boolean
) =>
  ((fieldError || fullValueError || attributeValidationError) && !isReadOnly) ||
  isUpdateActionValueRequestFailed;

const DateTime = (props: IDateTimeProps) => {
  const {
    component,
    handleChecklistInputChange,
    categoryId,
    isActionSelected,
    storageType,
    autoSave,
    isReadOnly,
    isUpdateActionValueRequestFailed,
    sectionItemId,
  } = props;

  const dispatch = useDispatch();
  const { primaryComparison, secondaryComparison } = useAttributeComparison(
    storageType,
    component.primaryComparison,
    component.secondaryComparison
  );

  const reduxDate = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.checklistComponents[
        component.uniqueID
      ]?.component?.value
  );

  useEffect(() => {
    const date = moment(selectedDate);
    const time = moment(selectedTime);

    if (date.isValid() && time.isValid()) {
      const formattedDate = date.format(MOMENT_ISO_FORMAT);
      const formattedTime = time.format(timeFormat);
      const value = `${formattedDate}T${formattedTime}`;
      handleValidation(new Date(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryComparison, secondaryComparison]);

  const changingActions = useSelector(
    (state: IState) =>
      state.checklist.documentsState[storageType]?.actionsBeingSetVisible
  );

  const validateInputDate = (date: Date | null): IValidationResult => {
    if (!isActionSelected) {
      return {
        hasError: false,
      };
    }

    return validateDateField(date ? date : null, {
      required: component.required,
      daysBeforeLimit: component.daysBeforeLimit,
      daysAfterLimit: component.daysAfterLimit,
      primaryComparison: primaryComparison,
      secondaryComparison: secondaryComparison,
    });
  };

  const getInitialValue = () => {
    const dateTimeFormat = component.useSeconds
      ? DATETIME_SECONDS_FORMAT
      : DATETIME_FORMAT;

    if (reduxDate) {
      return reduxDate.endsWith('Z')
        ? new Date(formatDateTime(reduxDate, dateTimeFormat))
        : new Date(reduxDate);
    }

    // 'Z' indicates that the date and time are represented in Coordinated Universal Time (UTC).
    // All the datetime coming from API servers will be in UTC time format.
    // At the initial rendering of date time required formatting to care site specific date time.

    if (component.value) {
      return new Date(formatDateTime(component.value, dateTimeFormat));
    }

    return null;
  };

  const initialValue = getInitialValue();
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    initialValue ?? null
  );
  const [selectedTime, setSelectedTime] = useState<Date | null>(
    initialValue ?? null
  );

  useEffect(() => {
    setSelectedDate(initialValue);
    setSelectedTime(initialValue);

    dispatch(
      setEntityAttributesBeingSetValue({
        storageType,
        value: reduxDate ?? component.value,
        fieldName: props.fieldName,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDate, component.value]);

  const previousSelectedDate = usePrevious(selectedDate);
  const previousReduxDate = usePrevious(reduxDate);

  useEffect(() => {
    if (
      isEqual(
        new Date(previousSelectedDate as unknown as Date),
        new Date(reduxDate as unknown as Date)
      )
    ) {
      return;
    }
    if (
      isEqual(
        new Date(previousReduxDate as unknown as Date),
        new Date(reduxDate as unknown as Date)
      )
    ) {
      return;
    }
    if (!previousSelectedDate && !reduxDate) {
      return;
    }
    if (
      moment(reduxDate).format(MOMENT_DATETIME_ISO_FORMAT).toString() ===
      moment(component.value).format(MOMENT_DATETIME_ISO_FORMAT).toString()
    ) {
      return;
    }

    if (reduxDate !== undefined) {
      const date = reduxDate ? new Date(reduxDate) : null;
      handleValidation(date);
      setSelectedDate(date);
      setSelectedTime(date);
      saveDate(date, date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxDate]);

  const careSiteNow = getCareSiteNow().toDate();

  useEffect(() => {
    if (
      component.useCurrentDate &&
      changingActions?.some((x) => x.actionId === sectionItemId! && x.selected)
    ) {
      handleValidation(careSiteNow);
      setSelectedDate(careSiteNow);
      setSelectedTime(careSiteNow);
      saveDate(careSiteNow, careSiteNow);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changingActions]);

  const [isShortcutSelected, setIsShortcutSelected] = useState(false);

  const [dateError, setDateError] = useState(
    validateInputDate(selectedDate).hasError
  );
  const [timeError, setTimeError] = useState(
    validateInputDate(selectedTime).hasError
  );

  const [fullValueError, setFullValueError] = useState(
    validateInputDate(selectedDate).hasError ||
      validateInputDate(selectedTime).hasError
  );

  const [attributeComparisonError, setAttributeComparisonError] =
    useState<IValidationResult>({ hasError: false });

  const minDate = hasValue(component.daysBeforeLimit)
    ? subDays(careSiteNow, component.daysBeforeLimit)
    : moment('1900-01-01').toDate();

  const maxDate = hasValue(component.daysAfterLimit)
    ? addDays(careSiteNow, component.daysAfterLimit)
    : moment('2100-01-01').toDate();

  const withChangedTime = (dateTime: Date | null, time: Date) => {
    if (dateTime) {
      const dateWithNewTime = new Date(dateTime);
      dateWithNewTime.setHours(
        time.getHours(),
        time.getMinutes(),
        time.getSeconds()
      );
      return dateWithNewTime;
    }
    return null;
  };

  const handleTimeChange = (time: Date | null) => {
    const timeResult = validateInputDate(time);
    const dateResult = validateInputDate(selectedDate);

    const hasTimeError =
      timeResult.hasError || ((!time && selectedDate) as boolean);
    const hasDateError =
      dateResult.hasError ||
      (!timeResult.hasError && ((time && !selectedDate) as boolean));

    setTimeError(hasTimeError);
    setDateError(hasDateError);
    setSelectedTime(time);
    if (
      !dateResult.hasError &&
      !timeResult.hasError &&
      dayjs(selectedDate).isValid() &&
      dayjs(time).isValid()
    ) {
      saveDate(selectedDate, time);
    }
  };

  const handleDateChange = (inputDate: Date | null) => {
    const date = inputDate;
    const dateResult = validateInputDate(date);
    const timeResult = validateInputDate(selectedTime);
    setDateError(dateResult.hasError);
    setTimeError(timeResult.hasError);
    setSelectedDate(date);
    if (
      !dateResult.hasError &&
      !timeResult.hasError &&
      ((dayjs(date).isValid() && dayjs(selectedTime).isValid()) || !date)
    ) {
      const time = selectedTime
        ? withChangedTime(careSiteNow, selectedTime)
        : null;
      saveDate(date, time);
    }
  };

  const handleShortCutSelected = (label?: string) => {
    if (label === 'Now' || isNaN(selectedTime?.getTime() as number)) {
      const now = getCareSiteNow().toDate();
      setSelectedTime(now);
      setIsShortcutSelected(true);
    }
  };

  const handleValidation = (date: Date | null): IValidationResult => {
    const result = validateInputDate(date);
    setFullValueError(result.hasError);

    if (!result.hasError) {
      const attributComparisonResult = validateAttributeComparisonDates(
        date,
        primaryComparison,
        secondaryComparison
      );

      setAttributeComparisonError(attributComparisonResult);
    }

    return result;
  };

  useEffect(() => {
    handleValidation(selectedTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActionSelected]);

  useEffect(() => {
    if (isShortcutSelected) {
      handleTimeChange(selectedTime);
      handleDateChange(selectedDate);
      setIsShortcutSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShortcutSelected]);

  const timeFormat = component.useSeconds
    ? MOMENT_TIME_SECONDS_FORMAT
    : MOMENT_TIME_FORMAT;

  const saveDate = (
    currSelectedDate: Date | null,
    currSelectedTime: Date | null
  ) => {
    if (!currSelectedDate) {
      handleChecklistInputChange('', component);
      return;
    }

    const date = moment(currSelectedDate).format(MOMENT_ISO_FORMAT);
    const time = moment(currSelectedTime).format(timeFormat);
    const value = `${date}T${time}`;
    const { hasError } = handleValidation(new Date(value));

    if (!hasError) {
      handleChecklistInputChange(value, component, undefined, props.fieldName);
    }
  };

  const errorMessage = getDateTimeErrorMessage(
    dateError,
    timeError,
    fullValueError,
    attributeComparisonError
  );

  useChecklistValidation(
    dateError ||
      timeError ||
      fullValueError ||
      attributeComparisonError.hasError,
    autoSave,
    isActionSelected,
    isReadOnly,
    component.uniqueID,
    categoryId,
    component.userDefinedId || component.componentType,
    errorMessage,
    storageType
  );

  const renderLinkedComponents = () => {
    return component.mirrorGroup || component.oneWaySrc ? (
      <LinkFieldsIcon linkMessage={component.linkMessage as string} />
    ) : null;
  };

  const isDateError =
    isActionSelected &&
    isError(
      dateError,
      fullValueError,
      attributeComparisonError.hasError,
      isReadOnly,
      isUpdateActionValueRequestFailed
    );

  const isTimeError =
    isActionSelected &&
    isError(
      timeError,
      fullValueError,
      attributeComparisonError.hasError,
      isReadOnly,
      isUpdateActionValueRequestFailed
    );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <StyledWrapper>
        <DateComponent
          open={props.open}
          testId="KeyboardDatePicker"
          required={component.required}
          readOnly={isReadOnly}
          placeholder="mm/dd/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          onShortcutSelected={handleShortCutSelected}
          minDate={minDate}
          maxDate={maxDate}
          error={isDateError}
          {...(component.userDefinedId
            ? { 'data-userdefinedid': component.userDefinedId }
            : '')}
          color={component.color ?? undefined}
        />
      </StyledWrapper>
      <TimeComponent
        value={selectedTime}
        onChange={handleTimeChange}
        isReadOnly={isReadOnly}
        placeholder={getTimePlaceholder(component.useSeconds)}
        required={component.required}
        timeFormat={timeFormat}
        testId="KeyboardTimePicker"
        useSeconds={component.useSeconds}
        error={isTimeError}
        {...(component.userDefinedId
          ? { 'data-userdefinedid': component.userDefinedId }
          : '')}
        color={component.color ?? undefined}
      />
      {renderLinkedComponents()}
    </div>
  );
};
export default DateTime;
