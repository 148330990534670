import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import styled from 'styled-components';
import { IState } from 'store';
import { ChecklistStorageType } from 'store/actions/types';
import { useChecklistForSidebar } from 'hooks/useChecklistForSidebar';
import Panel from 'components/panel';
import { ICONS } from 'components/icon';
import { hasUserPrivilege } from 'util/helpers/privilegeHelper';
import { Privilege } from 'store/roles/types';
import EpisodeSummaryDialog from './EpisodeSummaryDialog';
import { useGetEpisodeSummaryQuery } from 'graphql/hooks/getEpisodeSummary';
import {
  UpdateEpisodeSummaryMutation,
  useUpdateEpisodeSummaryMutation,
} from 'graphql/hooks/updateEpisodeSummary';
import Loader from 'components/loader';
import ActionButton from 'components/scenario/ActionButton';
import MultiLineText from 'components/text/MultiLineText';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateEpisodeSummaryDirtyState,
  clearEpisodeSummaryDirtyState,
} from 'store/ui/dirtyConfirmationModal/episodeSummary';

export interface IEpisodeSummaryProps {
  storageType: ChecklistStorageType;
}

const StyledHeader = styled.div`
  padding: 14px 2px 8px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledError = styled.div`
  padding: 2px 2px 2px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: red;
`;
const StyledBody = styled.div`
  padding: 14px 2px 8px 14px;
  display: flex;
  justify-content: space-between;
`;

const StyledWrapper = styled.div`
  margin-bottom: 10px;
`;
const StyledTextField = styled(TextField)<{ $color?: string }>`
    backgroundColor: '#f5f5f5',
    borderRadius: 4,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ddd',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#aaa',
  },
  ,
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {   
    ${({ $color }) => ($color ? `borderColor: ${$color};` : '')}
  },  
}`;

const StyledScrollableSection = styled.div`
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
`;

const ViewWrapper = styled(Box)({
  width: '100%',
  maxWidth: 395,
});
const EditableActions = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  mt: 0.5,
  gap: 0.5,
});
interface IMessageState {
  active: boolean;
  text: string;
}

const EpisodeSummary = (props: IEpisodeSummaryProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState<IMessageState | null>();
  const [summary, setSummary] = useState('');
  const [draftSummary, setDraftSummary] = useState('');
  const [isSummaryDialogOpen, setIsSummaryDialogOpen] = useState(false);
  const { storageType } = props;
  const { checklist } = useChecklistForSidebar(storageType);
  const episodeId = (checklist?.episodeId as number) ?? 0;
  const dispatch = useDispatch();
  const isReadOnlyWorkflow = useSelector(
    (state: IState) => state.mozart.isReadOnlyWorkflow
  );
  const isReadOnly =
    storageType === ChecklistStorageType.MOZART && isReadOnlyWorkflow;
  const { data, isFetching: isFetchingDetails } = useGetEpisodeSummaryQuery(
    {
      episodeId: episodeId,
    },
    { skip: episodeId <= 0, refetchOnMountOrArgChange: true }
  );
  const [updateEpisodeSummary, { isLoading: isEpisodeSummaryLoading }] =
    useUpdateEpisodeSummaryMutation();
  const episodeSummaryState = useSelector(
    (state: IState) => state.ui.dirtyConfirmationModal.episodeSummary
  );
  const isEpisodeSummaryDirty = episodeSummaryState.isDirty;
  const shouldPersistEditable = episodeSummaryState.persistEditable;
  const episodeSummaryPrivilege = hasUserPrivilege(
    Privilege.AllowSeeEpisodeSummary
  );

  useEffect(() => {
    if (!isFetchingDetails && data?.getEpisodeSummary?.summary) {
      setSummary(data.getEpisodeSummary.summary);
    }
  }, [data?.getEpisodeSummary?.summary, isFetchingDetails]);

  useEffect(() => {
    if (!isEpisodeSummaryDirty) {
      setIsEditing(shouldPersistEditable);
    }
  }, [isEpisodeSummaryDirty, shouldPersistEditable]);

  const handleEditClick = () => {
    setDraftSummary(summary);
    updateMessage(false, '');
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    updateMessage(false, '');

    if (validateSummary(draftSummary)) {
      updateEpisodeSummary({ episodeId: episodeId, summary: draftSummary })
        .then((data) => {
          const result = data as { data: UpdateEpisodeSummaryMutation };
          if (!result?.data?.updateEpisodeSummary?.success) {
            updateMessage(
              true,
              result.data.updateEpisodeSummary?.message ?? ''
            );
          }
          setSummary(draftSummary);
          setIsEditing(false);
          dispatchDirty(false);
        })
        .catch(() => {
          const errorText = 'Failed to save episode summary. Please try again.';
          updateMessage(true, errorText);
        });
    }
  };

  const updateMessage = (active: boolean, text: string) => {
    setMessage({
      active: active,
      text: text,
    });
  };

  const setSummaryDialogState = (open: boolean) => {
    setIsSummaryDialogOpen(open);
  };

  const validateSummary = (summary: string) => {
    if (summary.length === 0) {
      updateMessage(true, 'Summary cannot be empty');
      return false;
    }
    if (summary.length > 4000) {
      updateMessage(true, 'Summary cannot exceed 4000 characters');
      return false;
    }
    return true;
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    updateMessage(false, '');
    dispatchDirty(false);
  };

  const handleEpisodeSummaryDialog = () => {
    if (!episodeSummaryPrivilege) {
      return;
    }

    setSummaryDialogState(true);
  };

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const summaryText = event.target.value;
    setDraftSummary(summaryText);
    updateMessage(false, '');
    dispatchDirty(summaryText.trim() !== summary.trim(), true);
  };

  const dispatchDirty = (isDirty: boolean, persistEditable?: boolean) => {
    if (isDirty) {
      dispatch(
        updateEpisodeSummaryDirtyState({
          episodeId: episodeId ?? 0,
        })
      );
    } else {
      dispatch(
        clearEpisodeSummaryDirtyState({
          persistEditable: persistEditable ?? false,
        })
      );
    }
  };

  const renderHistoryButton = () => {
    return (
      <ActionButton
        handleClick={handleEpisodeSummaryDialog}
        icon={ICONS.Update}
        testId="summary-history-dialog"
        tooltipText="Show History"
        text={''}
        disabled={!episodeSummaryPrivilege}
      />
    );
  };

  return (
    <StyledWrapper>
      <Panel padding="0px 0px 10px 0px">
        <StyledHeader>
          <Typography variant="h4">Episode Summary</Typography>
        </StyledHeader>
        <StyledError>
          <Typography>{message?.active ? message?.text : ''}</Typography>
        </StyledError>
        <StyledBody>
          <ViewWrapper>
            {!isEditing ? (
              <>
                <Typography variant="body1">
                  <StyledScrollableSection>
                    <MultiLineText text={summary} />
                  </StyledScrollableSection>
                </Typography>
                {!isReadOnly && (
                  <EditableActions>
                    {renderHistoryButton()}
                    <ActionButton
                      handleClick={handleEditClick}
                      icon={ICONS.EditAlt}
                      testId="summary-edit-icon"
                      tooltipText="Edit Summary"
                      text={''}
                      disabled={!episodeSummaryPrivilege}
                    />
                  </EditableActions>
                )}
              </>
            ) : (
              <>
                <StyledTextField
                  label=""
                  multiline
                  rows={7}
                  variant="outlined"
                  fullWidth
                  value={draftSummary}
                  onChange={(e) => {
                    handleOnChange(e);
                  }}
                />
                <EditableActions>
                  {renderHistoryButton()}

                  <ActionButton
                    handleClick={handleCancelClick}
                    icon={ICONS.Close}
                    testId="summary-cancel-icon"
                    tooltipText="Cancel Summary"
                    text={''}
                    disabled={!episodeSummaryPrivilege}
                  />

                  <ActionButton
                    handleClick={handleSaveClick}
                    icon={ICONS.Checkmark}
                    testId="summary-save-icon"
                    tooltipText="Save Summary"
                    text={''}
                    disabled={!episodeSummaryPrivilege}
                  />
                </EditableActions>
              </>
            )}
          </ViewWrapper>
        </StyledBody>
      </Panel>
      {isSummaryDialogOpen != undefined && isSummaryDialogOpen ? (
        <EpisodeSummaryDialog
          open={isSummaryDialogOpen}
          onClose={() => setSummaryDialogState(false)}
          episodeId={episodeId ?? 0}
        />
      ) : null}
      <Loader active={isEpisodeSummaryLoading || isFetchingDetails} />
    </StyledWrapper>
  );
};

export default EpisodeSummary;
