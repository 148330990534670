import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import styled from 'styled-components';
import { CellComponentStyle } from 'components/gridFormatters';

const StyledDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const EditComponent = (props: TableEditColumn.CellProps) => {
  if (props.row.isReadonly) {
    return (
      <TableEditColumn.Cell {...props} style={CellComponentStyle}>
        <StyledDiv />
      </TableEditColumn.Cell>
    );
  }
  return (
    <TableEditColumn.Cell {...props} style={CellComponentStyle}>
      <StyledDiv>{props.children}</StyledDiv>
    </TableEditColumn.Cell>
  );
};
