import {
  Filter,
  Sorting,
  SortingDirection,
  TableColumnWidthInfo,
} from '@devexpress/dx-react-grid';
import { ISystemValue } from 'backend/types/systemValue';
import {
  IColumn,
  IDataResult,
  IFilterValue,
  IHomeState,
  IHomeStateModel,
  IMainModel,
  SearchTab,
} from './types';
import {
  CLEAR_HOME_MODEL,
  HOME_FETCH_ROWS_FAIL,
  HOME_FETCH_ROWS_START,
  HOME_FETCH_ROWS_SUCCESS,
  HOME_FORCE_UPDATE,
  HOME_GET_MAIN_MODEL_SUCCESS,
  HOME_MODEL_SUCCESS,
  HOME_OPEN_ADVANCED_SEARCH,
  HOME_OPEN_SEARCH,
  HOME_RESET_SELECTION,
  HOME_SET_ACTIVE_TAB,
  HOME_SET_SHOW_ACTIVE,
  HOME_TAB_MODEL_SUCCESS,
  HOME_UPDATE_COLUMN_WIDTH,
  HOME_UPDATE_EPISODE_STATE,
  HOME_UPDATE_FILTERS,
  HOME_UPDATE_FULL_SEARCH,
  HOME_UPDATE_PAGE,
  HOME_UPDATE_PAGE_SIZE,
  HOME_UPDATE_SELECTION,
  HOME_UPDATE_SORTING,
  HOME_OPEN_DASHBOARD_SEARCH,
  IHomeActions,
} from './action';
import { ILookupValue } from 'backend/types/lookupValue';
import { ILookup } from 'backend/types/lookup';
import { EnumSortDirection, User } from 'graphql/graphqlTypes';
import { MyListTab } from 'store/home/types';

export const initialHomeState: IHomeState = {
  homeModel: {
    activeTab: 0,
  } as IHomeStateModel,
  error: null,
  cnt: 0,
  loading: false,
  columns: [] as IColumn[],
  data: {
    rows: [] as Array<unknown>,
    count: 0 as number,
  } as IDataResult,
  filterValues: [] as IFilterValue[],
  showProgress: false,
  forceUpdate: false,
  mainModel: {
    users: [] as User[],
    lookups: [] as ILookup[],
    enabledPopulations: [] as ILookupValue[],
    glossaryItems: [] as ISystemValue[],
    systemValues: [] as ISystemValue[],
  } as IMainModel,
};

const getOrderBy = (columns: IColumn[]) =>
  columns
    .filter((x) => x.sortOrder > 0)
    .sort((a, b) => a.sortOrder - b.sortOrder)
    .map((y) => ({
      columnName: y.fullPropertyName,
      direction: (y.sortDirection === EnumSortDirection.Asc
        ? 'asc'
        : 'desc') as SortingDirection,
    }));

export const homeReducer = (
  state: IHomeState = initialHomeState,
  action: IHomeActions
): IHomeState => {
  switch (action.type) {
    case CLEAR_HOME_MODEL:
      return {
        ...state,
        homeModel: { activeTab: MyListTab } as IHomeStateModel,
        data: {
          rows: [] as Array<unknown>,
          count: 0 as number,
        } as IDataResult,
      };

    case HOME_FETCH_ROWS_START:
      return {
        ...state,
        data: {
          rows: [] as Array<unknown>,
          count: 0 as number,
        } as IDataResult,
        loading: true,
        cnt: state.cnt + 1,
      };

    case HOME_FORCE_UPDATE:
      return {
        ...state,
        forceUpdate: action.refetchHomeData,
      };

    case HOME_MODEL_SUCCESS:
      return {
        ...state,
        homeModel: {
          ...action.model,
          activeTab: state.homeModel.activeTab,
          filterBy: state.homeModel.filterBy,
          orderBy: state.homeModel.orderBy,
        } as IHomeStateModel,
      };

    case HOME_SET_ACTIVE_TAB:
      return {
        ...state,
        columns: [],
        homeModel: {
          ...state.homeModel,
          activeTab: action.index,
          filterBy: [] as Filter[],
          orderBy: [] as Sorting[],
          selection: [] as (string | number)[],
          page: 0,
          fullSearch: null,
          advancedSearchOption: null,
          dashBoardSearchOption: null,
        },
      };

    case HOME_UPDATE_SORTING:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          orderBy: action.sorting,
        },
      };

    case HOME_UPDATE_PAGE:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          page: action.page,
          selection: [] as (string | number)[],
        },
      };

    case HOME_UPDATE_PAGE_SIZE:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          settings: {
            ...state.homeModel.settings,
            pageSize: action.size,
          },
          page: 0,
          selection: [] as (string | number)[],
        },
      };

    case HOME_UPDATE_FILTERS:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          filterBy: action.filters,
          selection: [] as (string | number)[],
          page: 0,
        },
      };

    case HOME_UPDATE_FULL_SEARCH: {
      if ((state.homeModel.fullSearch ?? '') != (action.term ?? '')) {
        return {
          ...state,
          homeModel: {
            ...state.homeModel,
            fullSearch: action.term,
            selection: [] as (string | number)[],
            page: 0,
          },
        };
      }
      return state;
    }

    case HOME_UPDATE_EPISODE_STATE:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          episodeState: action.episodeState,
          selection: [] as (string | number)[],
          page: 0,
        },
      };

    case HOME_SET_SHOW_ACTIVE:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          settings: {
            ...state.homeModel.settings,
            showActive: action.active,
          },
        },
      };

    case HOME_TAB_MODEL_SUCCESS:
      return {
        ...state,
        columns: action.columns,
        filterValues: action.filterValues,
        showProgress: action.showProgress,
        homeModel: {
          ...state.homeModel,
          fullSearch: null,
          advancedSearchOption: null,
          dashBoardSearchOption: null,
          activeTab: state.homeModel.activeTab,
          episodeState: action.episodeState,
          orderBy: state?.homeModel?.orderBy?.length
            ? state.homeModel.orderBy
            : getOrderBy(action.columns),
          columnWidth: action.columns
            .map((x) => ({
              columnName: x.fullPropertyName,
              width: x.width ? x.width : 120,
            }))
            .concat({
              columnName: 'IsCompleted',
              width: 120,
            }) as TableColumnWidthInfo[],
        },
      };

    case HOME_UPDATE_COLUMN_WIDTH:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          columnWidth: action.width,
        },
      };

    case HOME_RESET_SELECTION:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          selection: [],
        },
      };

    case HOME_UPDATE_SELECTION:
      return {
        ...state,
        homeModel: {
          ...state.homeModel,
          selection: action.selection,
        },
      };

    case HOME_FETCH_ROWS_FAIL:
      return {
        ...state,
        cnt: state.cnt - 1,
        loading: state.cnt !== 1,
        data: {
          rows: [] as Array<unknown>,
          count: 0 as number,
        } as IDataResult,
        homeModel: {
          ...state.homeModel,
        },
        error: action.error,
        forceUpdate: false,
      };

    case HOME_FETCH_ROWS_SUCCESS:
      return {
        ...state,
        cnt: state.cnt - 1,
        loading: state.cnt !== 1,
        homeModel: {
          ...state.homeModel,
        },
        data: action.data,
        forceUpdate: false,
      };

    case HOME_GET_MAIN_MODEL_SUCCESS:
      return {
        ...state,
        mainModel: {
          users: action.users,
          lookups: action.lookups,
          enabledPopulations: action.enabledPopulations,
          glossaryItems:
            action.systemValues?.filter((it) => it.type === 'GLOSSARY') ??
            ([] as ISystemValue[]),
          systemValues:
            action.systemValues?.filter((it) => it.type === 'SYSTEM') ??
            ([] as ISystemValue[]),
        },
      };
    case HOME_OPEN_SEARCH:
      return {
        ...state,
        forceUpdate: true,
        homeModel: {
          ...state.homeModel,
          episodeState: -1,
          page: 0,
          activeTab: SearchTab,
          fullSearch: action.search,
          advancedSearchOption: null,
          dashBoardSearchOption: null,
          filterBy: [],
          orderBy: [],
          settings: {
            ...state.homeModel.settings,
            showActive: null,
          },
        },
      };
    case HOME_OPEN_ADVANCED_SEARCH:
      return {
        ...state,
        forceUpdate: true,
        homeModel: {
          ...state.homeModel,
          episodeState: -1,
          page: 0,
          activeTab: SearchTab,
          advancedSearchOption: action.search,
          dashBoardSearchOption: null,
          fullSearch: null,
          filterBy: null,
          orderBy: [],
          settings: {
            ...state.homeModel.settings,
            showActive: null,
          },
        },
      };
    case HOME_OPEN_DASHBOARD_SEARCH:
      return {
        ...state,
        forceUpdate: true,
        homeModel: {
          ...state.homeModel,
          episodeState: -1,
          page: 0,
          activeTab: SearchTab,
          dashBoardSearchOption: action.search,
          fullSearch: null,
          advancedSearchOption: null,
          filterBy: [],
          orderBy: [],
          settings: {
            ...state.homeModel.settings,
            showActive: null,
          },
        },
      };
    default:
      return state;
  }
};
