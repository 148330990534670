import 'regenerator-runtime/runtime';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  SymphonyTable,
  SymphonyTableHeaderRow,
} from 'components/gridFormatters';
import { Grid, TableSummaryRow } from '@devexpress/dx-react-grid-material-ui';
import { IState } from 'store';
import styled from 'styled-components';
import { useGetEpisodeWorkflowMdStepCountQuery } from 'graphql/hooks/getEpisodeWorkflowMDStepCount';
import {
  dashBoardAuthorization,
  dashBoardType,
  dashBoardDataColumnNames,
  mdDashBoardGridColumns,
  mdDataColumnsExtensionStyles,
  totalMdSummaryItems,
} from './DashBoardGridHelper';
import Loader from 'components/loader';
import { EpisodeWorkflowMdStepCountViewModel } from 'graphql/graphqlTypes';
import { IUserPreferences } from 'backend/types/userPreferences';
import DashValueProvider from './DashValueProvider';
import CountLinkProvider from '../providers/CountLinkProvider';
import { DashboardCareSiteRole } from 'consts/roles';
import TooltipProvider from '../providers/TooltipProvider';
import { IntegratedSummary, SummaryState } from '@devexpress/dx-react-grid';
import { summaryItem } from './DashBoardGrid';

const StyledGrid = styled(Grid)({
  marginTop: '20px',
  marginBottom: '80px',
});

const MdDashBoardGrid = ({
  userPreferences,
}: {
  userPreferences: IUserPreferences;
}) => {
  const currentCareSiteId = useSelector(
    (state: IState) => state.user.currentUser.careSiteId
  );
  const currentRoleId = useSelector(
    (state: IState) => state.user.currentUser.roleId
  );
  const isDashboardAccess = currentRoleId == DashboardCareSiteRole.roleId;
  const gridColumnNames = mdDashBoardGridColumns
    .filter((column) => {
      return column.name !== dashBoardDataColumnNames.careSiteName;
    })
    .map((i) => i.name);
  const defaultAuth =
    userPreferences?.standardAuthorization ?? dashBoardAuthorization.all;
  const userId = useSelector((state: IState) => state.user.currentUser.id);
  const mdData = useGetEpisodeWorkflowMdStepCountQuery(
    {
      userId: userId,
      careSiteId: currentCareSiteId,
      defaultAuthorization: userPreferences.standardAuthorization,
    },
    {
      refetchOnMountOrArgChange: false,
    }
  );
  if (mdData.isFetching) {
    return <Loader active={true} />;
  }

  const dashBoardData = mdData?.data
    ?.getEpisodeWorkflowMdStepCount as EpisodeWorkflowMdStepCountViewModel[];

  const showTotalSummary = dashBoardData.length > 1;

  return (
    <StyledGrid
      data-testid="md-dashboard-grid"
      rows={dashBoardData}
      columns={mdDashBoardGridColumns}
      getRowId={(row) => row.careSiteId}
    >
      {showTotalSummary && <SummaryState totalItems={totalMdSummaryItems} />}
      <TooltipProvider for={[dashBoardDataColumnNames.careSiteName]} />
      {isDashboardAccess && <DashValueProvider for={gridColumnNames} />}
      {!isDashboardAccess && (
        <CountLinkProvider
          for={gridColumnNames}
          priority={null}
          defaultAuthorization={defaultAuth as dashBoardAuthorization}
          dashBoardType={dashBoardType.uMMdDashboard}
        />
      )}
      <SymphonyTable columnExtensions={mdDataColumnsExtensionStyles} />
      <SymphonyTableHeaderRow />
      {showTotalSummary && <IntegratedSummary />}
      {showTotalSummary && <TableSummaryRow itemComponent={summaryItem} />}
    </StyledGrid>
  );
};

export default MdDashBoardGrid;
