import React, { useCallback, useEffect, useState } from 'react';
import GridHeaderPanel, {
  homeModelSelector,
} from 'components/home/grid/GridHeaderPanel';
import { COLORS } from 'consts/styles';
import styled from 'styled-components';
import HomeGrid from 'components/home/HomeGrid';
import GridFilters from 'components/home/grid/GridFilters';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { SearchTab } from 'store/home/types';
import { RoleType } from 'store/roles/types';
import { DashboardCareSiteRole, DashboardTabName } from 'consts/roles';
import DashboardGridHeaderPanel from './grid/Dashboard/DashboardGridHeaderPanel';
import DashBoardGrid from './grid/Dashboard/DashBoardGrid';
import {
  dashBoardGridPriority,
  dashBoardType,
} from './grid/Dashboard/DashBoardGridHelper';
import DashboardGridHeader from './grid/Dashboard/DashboardGridHeader';
import { IUserPreferences } from 'backend/types/userPreferences';
import { useGetUserPreferencesQuery } from 'graphql/hooks/getUserPreferences';
import MdDashBoardGrid from './grid/Dashboard/MdDashBoardGrid';
import CmDashboardGrid from './grid/Dashboard/CmDashboardGrid';

const StyledTabsBody = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 20px 24px;
  border: 1px solid ${COLORS.GREY25};
  overflow: hidden;
  background: ${COLORS.WHITE};
`;

export interface IExportOption {
  isExportingGridData: boolean;
  exportSelection?: boolean;
}

const TabsBody = ({
  isLoadingCompleted,
  handleSetLoadingComplete,
}: {
  isLoadingCompleted: boolean;
  handleSetLoadingComplete: (loadingComplete: boolean) => void;
}) => {
  const [gridExportOptions, setGridExportOptions] = useState<IExportOption>({
    isExportingGridData: false,
    exportSelection: false,
  });
  const appliedFilters = useSelector(
    (state: IState) => state.home.homeModel.filterBy
  );
  const homeModel = useSelector(homeModelSelector);
  const roleType = useSelector((state: IState) => state.user.currentUser.type);
  const roleId = useSelector((state: IState) => state.user.currentUser.roleId);
  const isDashboardAccess = roleId == DashboardCareSiteRole.roleId;
  const tabName = isDashboardAccess
    ? DashboardTabName
    : homeModel?.tabs?.find((x) => x.index === homeModel?.activeTab)?.name ??
      '';

  const handleTriggerExportGridData = useCallback(
    (startExport: IExportOption) => {
      setGridExportOptions(startExport);
    },
    []
  );
  const [defaultAuthorization, setDefaultAuthorization] = useState('');
  const [priorityBy, setPriorityBy] = useState('');
  const [userPreferences, setUserPreferences] = useState<IUserPreferences>(
    {} as IUserPreferences
  );
  const { data } = useGetUserPreferencesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const hasUmDashboardAccess =
    isDashboardAccess || roleType === RoleType.NONCLINICAL;
  const hasCmDashboardAccess =
    isDashboardAccess || roleType === RoleType.CLINICAL;
  const showUmRnTatDashboard =
    hasUmDashboardAccess &&
    userPreferences.defaultDashboard === dashBoardType.umRnTatDashboard;
  const showUmMdDashboard =
    hasUmDashboardAccess &&
    userPreferences.defaultDashboard === dashBoardType.uMMdDashboard;
  const showCmManagerDashboard =
    hasCmDashboardAccess &&
    userPreferences.defaultDashboard === dashBoardType.cmManagerDashboard;

  useEffect(() => {
    if (data) {
      const userPreference = data?.getUserPreferences as IUserPreferences;
      setUserPreferences(userPreference);
    }
  }, [data]);

  const handleFetchChange = (_priority: string, auth: string) => {
    setDefaultAuthorization(auth);
    setPriorityBy(_priority);
  };

  return (
    <StyledTabsBody
      data-cy="grid"
      style={{
        borderTop:
          homeModel.activeTab === SearchTab
            ? `1px solid ${COLORS.GREY25}`
            : '0',
      }}
    >
      {tabName !== DashboardTabName ? (
        <>
          <GridHeaderPanel
            handleSetLoadingComplete={handleSetLoadingComplete}
            handleTriggerExportGridData={handleTriggerExportGridData}
          />
          {appliedFilters && appliedFilters.length > 0 ? (
            <GridFilters handleSetLoadingComplete={handleSetLoadingComplete} />
          ) : null}
          <HomeGrid
            isLoadingCompleted={isLoadingCompleted}
            handleSetLoadingComplete={handleSetLoadingComplete}
            handleTriggerExportGridData={handleTriggerExportGridData}
            gridExportOptions={gridExportOptions}
          />
        </>
      ) : (
        <>
          <DashboardGridHeaderPanel
            userPreferences={userPreferences}
            setUserPreferences={setUserPreferences}
          />
          {showUmRnTatDashboard && (
            <>
              <DashboardGridHeader
                headerName="Expedited Authorizations"
                setDefaultAuthorization={setDefaultAuthorization}
                handleFetchChange={handleFetchChange}
                priority={dashBoardGridPriority.expedited}
                setUserPreferences={setUserPreferences}
                userPreferences={userPreferences}
              />
              <DashBoardGrid
                isLoadingCompleted={isLoadingCompleted}
                headerName="Expedited Authorizations"
                priority={dashBoardGridPriority.expedited}
                defaultAuthorization={defaultAuthorization}
                priorityBy={priorityBy}
                userPreferences={userPreferences}
              />
              <DashboardGridHeader
                setDefaultAuthorization={setDefaultAuthorization}
                handleFetchChange={handleFetchChange}
                headerName="Standard Authorizations"
                priority={dashBoardGridPriority.standard}
                setUserPreferences={setUserPreferences}
                userPreferences={userPreferences}
              />
              <DashBoardGrid
                isLoadingCompleted={isLoadingCompleted}
                headerName="Standard Authorizations"
                priority={dashBoardGridPriority.standard}
                defaultAuthorization={defaultAuthorization}
                priorityBy={priorityBy}
                userPreferences={userPreferences}
              />
            </>
          )}
          {showUmMdDashboard && (
            <>
              <DashboardGridHeader
                headerName="UM MD Dashboard"
                setDefaultAuthorization={setDefaultAuthorization}
                handleFetchChange={handleFetchChange}
                priority={dashBoardGridPriority.standard}
                setUserPreferences={setUserPreferences}
                userPreferences={userPreferences}
              />
              <MdDashBoardGrid userPreferences={userPreferences} />
            </>
          )}
          {showCmManagerDashboard && (
            <CmDashboardGrid
              userPreferences={userPreferences}
              setUserPreferences={setUserPreferences}
            />
          )}
        </>
      )}
    </StyledTabsBody>
  );
};

export default TabsBody;
