import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IState } from 'store';
import { uniqBy } from 'lodash';
import { Grid, Typography, styled } from '@mui/material';
import { useSetCurrentUserPreferenceMutation } from 'graphql/hooks/setCurrentUserPreference';
import { ISelectItem, Select } from 'components/select';
import { dashBoardType, userPreference } from './DashBoardGridHelper';

const StyledGrid = styled(Grid)({
  alignItems: 'center',
  height: '45px',
  justifyContent: 'space-between',
  rowSpacing: 0,
});

const StyledTypography = styled(Typography)`
  margin-left: 0px;
  margin-top: 6px;
  font-weight: bold;
  font-size: 14px;
`;

export interface CmDashboardGridHeaderProps {
  isDashboardCareSiteRole: boolean;
  careSiteId: string | null;
  onChange: (careSiteId: string) => void;
}

const CmDashboardGridHeader = ({
  isDashboardCareSiteRole,
  careSiteId,
  onChange,
}: CmDashboardGridHeaderProps) => {
  const roles = useSelector((state: IState) => state.roles.items);
  const allCareSites: ISelectItem[] = roles
    .filter((x) => x.careSiteId && x.careSiteId > 0)
    .map((x, index) => {
      if (index === 0) {
        return { id: '0', name: 'All' };
      }
      return { id: x.careSiteId.toString(), name: x.careSiteName };
    });
  const careSites = uniqBy<ISelectItem>(allCareSites, 'id');
  const [updateCurrentUserPreference] = useSetCurrentUserPreferenceMutation();
  const [selectedCareSiteId, setSelectedCareSiteId] = useState<string | null>(
    careSiteId ?? ''
  );

  const handleSelectChange = (event: React.ChangeEvent<{ value: string }>) => {
    const currentValue = event.target.value;
    setSelectedCareSiteId(currentValue);
    updateCurrentUserPreference({
      name: userPreference.cmRnCaseLoadDashboardFilter,
      value: currentValue,
    });
    onChange(currentValue);
  };

  return (
    <StyledGrid container>
      <Grid>
        <StyledTypography>{dashBoardType.cmManagerDashboard}</StyledTypography>
      </Grid>
      {isDashboardCareSiteRole && (
        <Grid>
          <Select
            items={careSites}
            value={selectedCareSiteId}
            onChange={handleSelectChange}
            size="small"
            fullWidth
            required
          />
        </Grid>
      )}
    </StyledGrid>
  );
};

export default CmDashboardGridHeader;
